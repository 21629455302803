.powered-by-kenect {
  letter-spacing: 2px;
  opacity: 0.5;
  margin: 10px 0 0 0;
}

.loading-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: aquamarine;
}

.App-logo {
  height: 40vmin;
}

.spinner {
  color: #4286f4;
}

.force-100-height {
  height: 100vh;
}

.review-sites-container {
  margin-top: 5%;
}

.public-pages .public-logo-img.FACEBOOK,
.review-settings-container .public-logo-img.FACEBOOK {
  height: 35px;
}

.public-pages .public-logo-img.GOOGLE,
.review-settings-container .public-logo-img.GOOGLE {
  height: 50px;
}

.public-pages .public-logo-img.SUBMIT_FEEDBACK,
.review-settings-container .public-logo-img.SUBMIT_FEEDBACK {
  height: 40px;
  margin-top: 15px;
}

.public-pages .public-logo-img.TRIP_ADVISOR,
.review-settings-container .public-logo-img.TRIP_ADVISOR {
  height: 30px;
  margin-top: 15px;
}

.public-pages .public-logo-img.NICHE,
.review-settings-container .public-logo-img.NICHE {
  height: 50px;
  margin-top: 15px;
}

.public-pages .public-logo-img.REAL_SELF,
.review-settings-container .public-logo-img.REAL_SELF {
  height: 35px;
  margin-top: 15px;
}

.public-pages .public-logo-img.HEALTH_GRADES,
.review-settings-container .public-logo-img.HEALTH_GRADES {
  height: 70px;
  margin-top: 15px;
}

.public-pages .public-logo-img.CARS_REVIEWS,
.review-settings-container .public-logo-img.CARS_REVIEWS {
  height: 35px;
  margin-top: 15px;
}

.public-pages .public-logo-img.GREAT_SCHOOLS,
.review-settings-container .public-logo-img.GREAT_SCHOOLS {
  height: 100px;
  margin-top: 15px;
}

.public-pages .public-logo-img.WEB_MD,
.review-settings-container .public-logo-img.WEB_MD {
  height: 100px;
  margin-top: 15px;
}

.public-pages .public-logo-img.DEALER_RATER,
.review-settings-container .public-logo-img.DEALER_RATER {
  height: 30px;
  margin-top: 15px;
}

.public-pages .public-logo-img.EDMUNDS_REVIEWS,
.review-settings-container .public-logo-img.EDMUNDS_REVIEWS {
  height: 30px;
  margin-top: 15px;
}

.public-pages .public-logo-img.VITALS_REVIEWS,
.review-settings-container .public-logo-img.VITALS_REVIEWS {
  height: 45px;
  margin-top: 15px;
}

.public-pages .public-logo-img.TRUST_PILOT,
.review-settings-container .public-logo-img.TRUST_PILOT {
  height: 72px;
  width: 200px;
}

.public-pages .public-logo-img.THE_KNOT,
.review-settings-container .public-logo-img.THE_KNOT {
  height: 50px;
}

.public-pages .public-logo-img.WEDDING_WIRE,
.review-settings-container .public-logo-img.WEDDING_WIRE {
  width: 200px;
}

.public-locationName-header {
  height: 140px;
  font-size: 50px;
  line-height: 44px;
  padding-right: 10px;
  padding-left: 10px;
}

.public-click-below-text {
  font-size: 30px;
  margin-bottom: 10px !important;
  margin-top: 20px;
}

.public-logo-header {
  height: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 632px) {
  .public-locationName-header {
    font-size: 11vw;
    line-height: 10.5vw;
    font-weight: 500;
  }
  .public-click-below-text {
    font-size: 8vw;
    line-height: 8.3vw;
    font-weight: 100;
  }
}

.public-feedback-textarea {
  font-size: 12px;
  height: 100px !important;
  width: 200px;
}

.review-settings-container .custom-border {
  border-bottom: 1px solid #ced4da;
  height: 0px;
}

.review-settings-container
  .review-site-select-list
  .review-site-select
  .form-check-input {
  margin-bottom: 20px;
}

.review-settings-container
  .review-site-select-list
  .review-site-select
  .priority-input {
  width: 50px;
}

.review-settings-container .review-site-select-list .review-site-select img {
  height: 50px;
  width: 50px;
}

.public-logo-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 60px;
  border-radius: 3px;
  background: linear-gradient(white, #e7e7e7);
  margin-bottom: 20px;
}
.public-logo-button.public-google-link {
  margin-top: 30px;
}
.public-logo-button:active {
  background: none !important;
  background-color: #e7e7e7 !important;
}

.select-container {
  margin-right: 10px !important;
  margin-left: 0px !important;
  position: relative !important;
  bottom: 1px !important;
}

.add-google-container .Select {
  min-width: 300px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.add-facebook .Select {
  min-width: 250px;
}

.social-connection-row {
  min-height: 48px;
}

.light-text {
  color: #666666;
}

.landing-img {
  max-width: 100%;
}

.kui-tile {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  overflow: hidden;
}

.paymentHeader {
  display: flex;
  gap: 17px;
  justify-content: center;
  border-bottom: var(--gray-border);
  flex-direction: column;
  align-items: center;
  min-height: fit-content;
  padding: 40px 0 31px;
  margin: 0 32px;
  flex-shrink: 0;
}

.paymentBody {
  color: var(--dark-gray-5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 40px 40px;
  flex-grow: 1;
  overflow-y: auto;
}

.paymentFormBody {
  gap: 32px;
}

.paymentMessageBody {
  justify-content: space-between;
  padding-top: 76px;
  min-height: 244px;
  gap: 76px;

  &.centeredBody {
    padding: 0;
    justify-content: center;
  }
}

.powered-by-kenect-small-text {
  font-weight: 500;
  a {
    color: var(--blue-link);
  }
}

@media only screen and (max-width: 768px) {
  .kui-gradient-background {
    background-image: none !important;
  }
  .kui-tile {
    width: 100vw !important;
    height: 100vh !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }
  .paymentHeader {
    margin: 0 16px;
  }
  .paymentBody {
    padding: 32px 24px 24px;
  }
  .paymentMessageBody {
    padding-top: 146px;
  }
}

.hidden {
  visibility: hidden;
}

.locationInfo {
  text-align: center;
  color: var(--dark-gray-1);
}

.cardImages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 28px;
}

#paymentLogo {
  max-width: 400px;
  max-height: 133px;
  object-fit: contain;
}

.transactionAmountBox {
  padding: 12px 16px 16px 16px;
  border: solid 1px var(--light-gray-3);
  border-radius: 8px;
  width: 100%;
}

.payment-option-box {
  padding: 8px 12px;
  border: solid 1px var(--light-gray-3);
  border-radius: 6px;
  min-height: 42px;
  overflow: hidden;
  display: flex;
}

.credit-card-option {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.credit-card-fields {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.show-card-fields {
  margin-top: 24px;
}

.hide-card-fields {
  visibility: hidden;
  height: 0;
}

.credit-card-option-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.radio-option-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.inline-card-types {
  display: flex;
  align-items: center;
  gap: 6px;

  img {
    height: 16px;
  }
}

#paymentAmount {
  font-size: 40px;
  line-height: 1;
}

.actionBtn {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 6px;
  background-color: var(--blue-3);
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.5s ease;

  &.disabled {
    background-color: var(--light-gray-3);
    color: var(--medium-gray-3);
  }
}

#payBtn:disabled {
  background-color: #90c0d2;
}

.formBlock {
  width: 100%;
}

.fieldHeader {
  font-weight: 700;
  width: 100%;
  font-family: inherit;
  margin-bottom: 2px;
}

.paymentFormWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.paymentField {
  height: 40px;
  width: 100%;
  border: 1px solid var(--light-gray-3);
  border-radius: 6px;
  padding: 8px 16px;

  font-family: inherit;
}

.addressFieldGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.left {
  border-right: none;
  border-radius: 7px 0 0 7px;
}
.right {
  border-radius: 0 7px 7px 0;
}
.center {
  border-radius: 0;
  border-right: none;
}

.halfWidthFlexChild {
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}

#ccnumber input,
#ccexp input,
#cvv input,
.customInputs {
  border: none;
  height: 100%;
  width: 100%;
}

input:focus,
button:focus {
  outline: none;
}

.error {
  color: #b70000;
  margin: 0 10px;
}

.clickable {
  cursor: pointer;
}

.background-white {
  background-color: white;
}

.completeIcon {
  color: #64af64;
}

.errorIcon {
  color: #af6464;
}

.processedIcon {
  font-size: 160px;
}

.faded-text {
  color: var(--dark-gray-1);
}

.hide {
  display: none;
}

.cardConnectIframe {
  height: 278px;
  border: none;
}

.lawPayFormFields {
  display: flex;
  flex-direction: column;
  gap: 32px;

  iframe {
    border: none;
    height: 30px;
    width: inherit;
  }
}

.statusMessage {
  font-weight: bold;
  &.success {
    color: var(--green-4);
  }
  &.error {
    color: var(--red-3);
  }
}

.feeDetailsModal {
  &.modal-dialog {
    max-width: 640px;
  }

  .surchargingModalContainer {
    background-color: var(--light-gray-1);
    border-radius: 8px;
    padding: 10px;
    height: 100%;

    @media only screen and (max-width: 768px) {
      background-color: inherit;
      padding: 0;
    }

    .statesRow {
      flex-basis: 76px;
    }

    .territoriesRow {
      flex-basis: 84px;
    }

    .armedForcesRow {
      flex-basis: 92px;
    }
  }
}

// Mobile toast alert overrides
@media only screen and (max-width: 768px) {
  .kui-alert.kui-toast-alert {
    border-radius: 0;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
  }
}
